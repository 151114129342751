import { css } from '@emotion/react'
import React from 'react'
import { HeadingTertiary } from '../../components/atoms/Heading/HeadingTertiary'
import { LinkBase } from '../../components/atoms/Link/LinkBase'
import { LinkWithIcon } from '../../components/atoms/Link/LinkWithIcon'
import {
  Base1,
  Base4,
  Base5,
  Base6,
  Base7,
  Base8,
} from '../../components/data/companyBases'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { AccordionOfficeList } from '../../components/molecules/Accordion/AccordionOfficeList'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'
import { hoverStyleColorRed } from '../../components/styles/hover'
import { styleTable } from '../../components/styles/table'

interface TableProps {
  tbody: {
    th: string
    td: {
      zipcode: string
      location: string
      mapLink: string
      phoneNumber: string
      phoneNumber2?: string
    }
  }[]
}

const styleTable2 = css`
  ${styleTable}
  th {
    text-align: center;
    vertical-align: middle;
    width: 170px;
    @media screen and (max-width: 1199px) {
      width: 108px;
    }
  }
`

const Table = ({ tbody }: TableProps) => {
  return (
    <table>
      <tbody>
        {tbody.map((row, i) => (
          <tr key={i}>
            <th>{row.th}</th>
            <td className="pc:relative">
              <p>{row.td.zipcode}</p>
              <p
                className="pc:pr-40"
                dangerouslySetInnerHTML={{ __html: row.td.location }}
              />
              <div
                className={`flex mt-3 space-x-3 pc:space-x-0 ${
                  row.td.phoneNumber2 ? 'items-start' : 'items-center'
                }`}
              >
                <LinkWithIcon href={row.td.mapLink} iconType="location">
                  MAP
                </LinkWithIcon>
                {row.td.phoneNumber2 ? ( //大阪支店のみ2事業部あり
                  <div className="pc:absolute right-6 top-1/2 pc:-translate-y-1/2">
                    {[row.td.phoneNumber, row.td.phoneNumber2].map(
                      (item, i) => (
                        <div key={i} className={i === 1 ? 'pt-3' : ''}>
                          <p className="text-xs pb-1 leading-tight">
                            {i === 0 ? '第一事業部' : '第二事業部'}
                          </p>
                          <a
                            className="font-Rajdhani font-medium text-md"
                            href={`tel:${item}`}
                          >
                            <span className="flex items-center space-x-1">
                              <svg
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path
                                    d="M13.0914 15.5C11.5634 15.5 10.0216 15.0796 8.46608 14.2389C6.91052 13.3982 5.50639 12.3068 4.25369 10.9646C3.00098 9.62242 1.97886 8.11799 1.18732 6.45133C0.395772 4.78466 0 3.12537 0 1.47345C0 1.32596 0.00688287 1.16372 0.0206488 0.986726C0.0344148 0.809735 0.0550638 0.647493 0.0825958 0.5H3.9646L4.74926 4.32743L2.41593 6.76106C3.20059 8.17699 4.07817 9.39749 5.04867 10.4226C6.01917 11.4476 7.13078 12.3289 8.38348 13.0664L10.6962 10.4779L14 11.2743V15.4336C13.8761 15.4484 13.7316 15.4631 13.5664 15.4779C13.4012 15.4926 13.2429 15.5 13.0914 15.5ZM1.941 5.85398L3.71681 4.0177L3.20059 1.49558H0.949853C0.922321 2.12979 0.998033 2.80826 1.17699 3.53097C1.35595 4.25369 1.61062 5.02802 1.941 5.85398ZM9.27139 13.531C9.79449 13.8112 10.4071 14.0435 11.1091 14.2279C11.8112 14.4122 12.4651 14.497 13.0708 14.4823V12.0708L10.9646 11.6062L9.27139 13.531Z"
                                    fill="#CC050B"
                                  />
                                </g>
                                <defs>
                                  <clipPath>
                                    <rect
                                      width="14"
                                      height="15"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span>{item}</span>
                            </span>
                          </a>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <a
                    className="pc:absolute font-Rajdhani font-medium text-md right-6 top-[calc(50%-8px)]"
                    href={`tel:${row.td.phoneNumber}`}
                  >
                    <span className="flex items-center space-x-1">
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path
                            d="M13.0914 15.5C11.5634 15.5 10.0216 15.0796 8.46608 14.2389C6.91052 13.3982 5.50639 12.3068 4.25369 10.9646C3.00098 9.62242 1.97886 8.11799 1.18732 6.45133C0.395772 4.78466 0 3.12537 0 1.47345C0 1.32596 0.00688287 1.16372 0.0206488 0.986726C0.0344148 0.809735 0.0550638 0.647493 0.0825958 0.5H3.9646L4.74926 4.32743L2.41593 6.76106C3.20059 8.17699 4.07817 9.39749 5.04867 10.4226C6.01917 11.4476 7.13078 12.3289 8.38348 13.0664L10.6962 10.4779L14 11.2743V15.4336C13.8761 15.4484 13.7316 15.4631 13.5664 15.4779C13.4012 15.4926 13.2429 15.5 13.0914 15.5ZM1.941 5.85398L3.71681 4.0177L3.20059 1.49558H0.949853C0.922321 2.12979 0.998033 2.80826 1.17699 3.53097C1.35595 4.25369 1.61062 5.02802 1.941 5.85398ZM9.27139 13.531C9.79449 13.8112 10.4071 14.0435 11.1091 14.2279C11.8112 14.4122 12.4651 14.497 13.0708 14.4823V12.0708L10.9646 11.6062L9.27139 13.531Z"
                            fill="#CC050B"
                          />
                        </g>
                        <defs>
                          <clipPath>
                            <rect
                              width="14"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{row.td.phoneNumber}</span>
                    </span>
                  </a>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const Page = () => {
  return (
    <LayoutDefault
      heading="グループ会社・拠点一覧"
      headingEnglish={ENGLISH_TITLE.GroupCompanies}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: 'グループ会社・拠点一覧', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <div>
        {[Base1, Base4, Base5, Base6, Base7, Base8].map((base, i) => (
          <section
            css={css`
              @media screen and (min-width: 1200px) {
                &:not(:first-of-type) {
                  margin-top: 56px;
                }
              }
              @media screen and (max-width: 1199px) {
                &:not(:first-of-type) {
                  margin-top: 64px;
                }
              }
            `}
            className="pc:py-12 pc:px-8 bg-white"
            key={i}
          >
            <HeadingTertiary label={base.name} />
            <div className="flex flex-col pc:flex-row flex-shrink-0 mt-8 pc:mt-10">
              <div>
                <div className="w-[335px] max-w-full h-[217px] pc:w-[294px] pc:h-[191px] mb-3 relative">
                  <img
                    src={base.thumbnail}
                    alt=""
                    className="absolute w-full h-full object-contain"
                    css={
                      base.thumbnailClass === 'thumb-border' &&
                      css`
                        border: solid 1px #f0f0f0;
                      `
                    }
                  />
                </div>
                <div className="pc:text-right">
                  <LinkBase css={hoverStyleColorRed} href={base.link}>
                    <span className="inline-flex items-center">
                      <span className="pr-1">公式サイトへ</span>
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.5 1.00001C6.5 0.723861 6.72386 0.5 7.00001 0.5H11.5001C11.7762 0.5 12.0001 0.723861 12.0001 1.00001V5.50007C12.0001 5.77621 11.7762 6.00007 11.5001 6.00007C11.2239 6.00007 11.0001 5.77621 11.0001 5.50007V1.50001H7.00001C6.72386 1.50001 6.5 1.27615 6.5 1.00001Z"
                          fill="#CC050B"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 3.00001C0 2.72386 0.223861 2.5 0.500007 2.5H4.50006C4.77621 2.5 5.00007 2.72386 5.00007 3.00001C5.00007 3.27615 4.77621 3.50001 4.50006 3.50001H1.00001V11.5001H9.00012V8.00007C9.00012 7.72393 9.22398 7.50007 9.50013 7.50007C9.77627 7.50007 10.0001 7.72393 10.0001 8.00007V12.0001C10.0001 12.2763 9.77627 12.5001 9.50013 12.5001H0.500007C0.223861 12.5001 0 12.2763 0 12.0001V3.00001Z"
                          fill="#CC050B"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.3536 1.14645C11.5489 1.34171 11.5489 1.6583 11.3536 1.85357L5.85357 7.35364C5.6583 7.5489 5.34171 7.5489 5.14645 7.35364C4.95118 7.15838 4.95118 6.84179 5.14645 6.64652L10.6465 1.14645C10.8418 0.951184 11.1584 0.951184 11.3536 1.14645Z"
                          fill="#CC050B"
                        />
                      </svg>
                    </span>
                  </LinkBase>
                </div>
              </div>
              <div className="mt-10 pc:mt-0 pc:ml-8">
                <dl className="text-md">
                  <div>
                    <dt className="font-bold">事業案内</dt>
                    <dd className="leading-normal mt-2">{base.service}</dd>
                  </div>
                  <div className="mt-8 pc:mt-6">
                    <dt className="font-bold">本社所在地</dt>
                    <dd className="leading-normal mt-2">
                      {base.zipcode}　{base.location}
                      <br />
                      <span className="font-Rajdhani">
                        TEL : {base.tel}
                        {base.fax !== undefined && `　FAX : ${base.fax}`}
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>

            {base.tbody !== undefined && (
              <div css={styleTable2} className="mt-8 pc:mt-10">
                <AccordionOfficeList body={<Table tbody={base.tbody} />} />
              </div>
            )}
          </section>
        ))}
      </div>
    </LayoutDefault>
  )
}

export default Page

export const Head = () => (
  <SEO title="グループ会社・拠点一覧" url="/company/bases" />
)
